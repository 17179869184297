import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tbd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private titleService: Title) {}
  ngOnInit(): void {
    const title = environment.route === 'fiasco' ? 'A Game About Powerful Ambition and Poor Impulse Control' : 'TBD';
    this.titleService.setTitle(title);
  }
}
