<tbd-navbar
  *ngIf="!outlet.activatedRouteData.hideNav && !outlet.activatedRouteData.hasSidebar && !isNavigating"
></tbd-navbar>

<main
  (click)="collapse()"
  [ngClass]="{
    'main-with-sidebar': outlet.activatedRouteData.hasSidebar,
    collapsed: isCollapsed,
    'hidden-nav': outlet.activatedRouteData.hideNav
  }"
>
  <div class="header">
    <tbd-toast [toast]="toast" (closeEvent)="onCloseEvent()" *ngIf="toast"></tbd-toast>
    <div class="header-row">
      <h1>{{ title || (!(isAuthenticated | async) ? 'waiting for sign in/sign up' : '') }}</h1>
      <ng-container *ngIf="!isNavigating">
        <ng-container *ngFor="let route of routes">
          <a class="primary" [routerLink]="getRoute(route)" routerLinkActive="active">{{ route.data.title }}</a>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div
    class="main-content"
    [@pageTransition]="prepareRoute(outlet)"
    [hidden]="!(isAuthenticated | async) || isNavigating"
  >
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
  <ng-container *ngIf="isNavigating && (isAuthenticated | async)">
    <div class="flex-h center loading">
      <div>
        <i class="fas fa-circle-notch fa-spin"></i>
      </div>
    </div>
  </ng-container>

  <div class="main-content" *ngIf="!isNavigating && !(isAuthenticated | async)">
    <tbd-card title="Sign In/Sign Up" [flex]="true" [toggle]="false">
      <div class="tbd-row centered">
        <button class="primary" (click)="auth()">Sign In/Sign Up</button>
      </div>
    </tbd-card>
  </div>
</main>
