import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DieStyleResolver } from '../resolvers/die-style.resolver';
import { UserResolver } from '../resolvers/user.resolver';

const routes: Record<string, Routes> = {
  default: [
    {
      path: 'dice',
      data: { title: 'dice' },
      canLoad: [UserResolver],
      resolve: { _: DieStyleResolver },
      /* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
      loadChildren: () => import('src/app/modules/dice.module').then((m) => m.DiceModule),
    },
    {
      path: 'players',
      data: { title: 'players' },
      canLoad: [UserResolver],
      /* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
      loadChildren: () => import('src/app/modules/players.module').then((m) => m.PlayersModule),
    },
    {
      path: 'sessions',
      data: { title: 'tools' },
      canLoad: [UserResolver],
      /* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
      loadChildren: () => import('src/app/modules/tools.module').then((m) => m.ToolsModule),
    },
    { path: '', redirectTo: 'dice/roll', pathMatch: 'full' },
  ],
  fiasco: [
    {
      path: 'game',
      data: { title: 'fiasco' },
      resolve: { _: DieStyleResolver },
      /* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
      loadChildren: () => import('src/app/modules/fiasco.module').then((m) => m.FiascoModule),
    },
    { path: '', redirectTo: 'game/play', pathMatch: 'full' },
  ],
  'fiasco-live': [
    {
      path: 'game',
      data: { title: 'fiasco' },
      resolve: { _: DieStyleResolver },
      /* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
      loadChildren: () => import('src/app/modules/fiasco.module').then((m) => m.FiascoModule),
    },
    { path: '', redirectTo: 'game/play', pathMatch: 'full' },
  ],
  babylon: [
    {
      path: 'babylon',
      data: { title: 'babylon' },
      /* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */
      loadChildren: () => import('src/app/modules/babylon.module').then((m) => m.BabylonModule),
    },
    { path: '', redirectTo: 'babylon/home', pathMatch: 'full' },
  ],
};

@NgModule({
  imports: [
    RouterModule.forRoot(routes[environment.route], {
      scrollPositionRestoration: 'top',
      scrollOffset: [0, 0],
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class RoutingModule {}
