import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Toast } from '../models/toast';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  toasts: BehaviorSubject<Toast[]> = new BehaviorSubject<Toast[]>([]);

  pushNotification(toast: Toast): void {
    const toasts = this.toasts.getValue();
    toasts.push(toast);
    this.toasts.next(toasts);
  }
}
