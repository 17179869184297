export class Player {
  id: string;
  name: string;
  class: string;
  race: string;
  image: string | null;
  color: string;
  flavor: string;
  stats: PlayerStats;
  status: PlayerStatus;
  actions: Action[];
  weapons: Action[];
  spells: Action[];
  isNpc: boolean;
  type: string;

  constructor(player?: PlayerEntity) {
    this.id = player?._id || '';

    this.actions = player?.actions || [];
    this.spells = player?.spells || [];
    this.weapons = player?.weapons || [];
    this.name = player?.name || 'N/A';
    this.class = player?.class || 'N/A';
    this.race = player?.race || 'N/A';
    this.image = player?.image || null;
    this.color = player?.color || '#fff';
    this.flavor = player?.flavor || 'N/A';
    this.stats = player?.stats || this.defaultStats;
    this.status = player?.status || { isAlive: true, isDead: false };
    this.isNpc = player?.isNpc || false;
    this.type = player?.type || '';
  }

  get isDead(): boolean {
    const isBelowZero = this.currentHealth < 0;
    const isAtTwiceMax = Math.abs(this.maxHealth / this.currentHealth) <= 1;
    return isBelowZero && isAtTwiceMax;
  }

  get currentHealth(): number {
    return this.stats?.health?.current || 0;
  }

  get maxHealth(): number {
    return this.stats?.health?.max || 0;
  }
  get healthStatus(): string {
    const ratio = this.currentHealth / this.maxHealth;
    if (isNaN(ratio)) {
      return 'neutral';
    }
    if (ratio >= 0.66) {
      return 'success';
    } else if (ratio < 0.66 && ratio >= 0.5) {
      return 'neutral';
    } else if (ratio < 0.5 && ratio > 0.33) {
      return 'warning';
    } else {
      return 'danger';
    }
  }

  get health(): string {
    return `${this.stats?.health?.current}/${this.stats?.health?.max}`;
  }

  get defaultStats(): PlayerStats {
    return {
      abilityScores: {
        str: 0,
        dex: 0,
        con: 0,
        wis: 0,
        cha: 0,
        int: 0,
      },
      savingThrows: {
        str: 0,
        dex: 0,
        con: 0,
        wis: 0,
        cha: 0,
        int: 0,
      },
      health: {
        current: 0,
        max: 0,
      },
      initiative: 0,
      armorClass: 0,
    };
  }

  removeAction(i: number): void {
    this.actions.splice(i, 1);
  }

  removeSpell(i: number): void {
    this.spells.splice(i, 1);
  }

  removeWeapon(i: number): void {
    this.weapons.splice(i, 1);
  }
}

export interface PlayerEntity {
  _id?: string;
  name: string;
  class: string;
  race: string;
  image: string;
  color: string;
  flavor: string;
  isNpc: boolean;
  order?: number;
  activeStats?: PlayerStats;
  stats?: PlayerStats;
  status?: PlayerStatus;
  actions?: Action[];
  spells?: Action[];
  weapons?: Action[];
  type: string;
}

export interface PlayerStatus {
  isAlive: boolean;
  isDead: boolean;
}
export interface PlayerStats {
  armorClass: number;
  health: HealthStat;
  initiative: number;
  savingThrows?: AbilityScores;
  abilityScores?: AbilityScores;
}

export interface HealthStat {
  max: number;
  current: number;
}

export interface AbilityScores {
  str: number;
  dex: number;
  con: number;
  wis: number;
  cha: number;
  int: number;
}

export interface Spell {
  name: string;
  damage: string;
  link: string;
}

// export interface Action {
//   name: string;
//   description: string;
// }

export class Action {
  id: string;
  name: string;
  description: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  constructor(action?: any) {
    this.id = action?.id || Math.random() * 1000000;
    this.name = action?.name || '';
    this.description = action?.description || '';
  }
}
