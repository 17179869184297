import { Player, PlayerEntity } from './player';

export class Session {
  id: string;
  name: string;
  initiative: any[] = [];
  activeId: string;
  date: Date;
  players: Player[] = [];
  playerIds: string[] = [];

  constructor(session: any) {
    this.id = session._id;
    this.name = session.name;
    this.initiative = session.initiative;
    this.activeId = session.activeId;
    this.date = new Date(session.date);
    this.players = session.players.map((p: PlayerEntity) => new Player(p));
  }
}
