<div class="toast">
  <button class="toast-icon close-button" (click)="close()" type="button" [attr.aria-label]="'TOAST.CLOSE'">
    <i class="fas fa-times"></i>
  </button>
  <h3 class="text" *ngIf="toast?.title">{{ toast.title }}</h3>
  <div class="text" *ngIf="toast?.description">{{ toast.description }}</div>
  <div class="flex-h" *ngIf="toast">
    <progress value="{{ progressPercentage }}" max="100"></progress>
  </div>
</div>
