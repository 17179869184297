export const environment = {
  production: true,
  apiURL: 'https://tbd-nest-api.herokuapp.com',
  origin: 'https://tbd.mattpreston.io',
  fathom: {
    site: 'UXYAFSYN',
  },
  stripe: {
    public: {
      key: 'pk_test_l52jTbTruv6CMhh6BTCQixDd',
    },
  },
  sentry: {
    dsn: 'https://c44794a91def4ca99c38c326188e487e@o386497.ingest.sentry.io/5592039',
    release: '13560215fc3544a7d5fd3f85c864a5abdaa48ed6',
  },
  auth0: {
    domain: 'tbd-project-staging.auth0.com',
    clientId: 'XGFSEh5N0X83FhtukFmF2sBTy0hEA1ui',
    audience: 'https://tbd-project-staging.auth0.com/api/v2/',
  },
  autoAuth: false,
  route: 'fiasco-live',
};
