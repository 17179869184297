import { timer } from 'rxjs';

export enum DieType {
  d4,
  d6,
  d8,
  d10,
  d12,
  d20,
  invalid,
}

export class Die {
  private _value: number | null;
  max: number;
  type: DieType;
  isRolling: boolean;
  isSelected: boolean;
  angle: number;
  style?: DieStyle | null;
  inverted: boolean;

  constructor(initial: {
    inverted?: boolean;
    value?: number;
    _value?: number;
    max: number;
    type: DieType;
    style?: DieStyle | null;
  }) {
    this._value = initial.value || null;
    this.value = initial.value || initial._value || null;
    this.max = initial.max;
    this.type = initial.type;
    this.isRolling = false;
    this.isSelected = false;
    this.angle = 0;
    this.style = initial.style;
    this.inverted = initial.inverted || false;
  }

  set value(value: number | null) {
    this._value = value;
  }

  get value(): number | null {
    return this._value;
  }

  get isD4(): boolean {
    return this.type == DieType.d4;
  }
  get isD6(): boolean {
    return this.type == DieType.d6;
  }
  get isD8(): boolean {
    return this.type == DieType.d8;
  }
  get isD10(): boolean {
    return this.type == DieType.d10;
  }
  get isD12(): boolean {
    return this.type == DieType.d12;
  }
  get isD20(): boolean {
    return this.type == DieType.d20;
  }

  roll(): void {
    if (this.isRolling) {
      return;
    }
    this.startSpinning();
  }

  clear(die: Die): void {
    die.value = null;
  }

  private startSpinning(): void {
    this.value = null;
    this.angle = 0;
    this.isRolling = true;

    setTimeout(() => {
      this.value = Math.ceil(Math.random() * this.max);
      this.isRolling = false;
    }, 500);
  }
}

export class DieStyle {
  id?: string;
  primaryColor: string;
  secondaryColor: string;
  primaryTextColor: string;
  secondaryTextColor: string;
  highlightColor: string;
  textSize: number;
  userId?: string;

  constructor(die: any) {
    if (die) {
      this.id = die._id;
      this.primaryColor = die.primaryColor;
      this.secondaryColor = die.secondaryColor;
      this.primaryTextColor = die.primaryTextColor;
      this.secondaryTextColor = die.secondaryTextColor;
      this.highlightColor = die.highlightColor;
      this.textSize = die.textSize;
      this.userId = die.userId;
    } else {
      // this.id = undefined;
      this.primaryColor = 'hsl(276, 90%, 25%)';
      this.secondaryColor = 'hsl(192, 100%, 50%)';
      this.primaryTextColor = 'hsl(0, 0%, 100%)';
      this.secondaryTextColor = 'hsl(0, 0%, 100%)';
      this.highlightColor = 'hsl(230, 89%, 28%)';
      this.textSize = 30;
    }
  }
}
