import { Component } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Toast } from 'src/app/models/toast';
import {
  Router,
  ActivatedRoute,
  Event,
  Route,
  Routes,
  RouterOutlet,
  NavigationStart,
  ActivationEnd,
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { pageTransition } from 'src/app/animations/page';
import { AuthService } from '@auth0/auth0-angular';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tbd-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [pageTransition],
})
export class MainComponent {
  isAuthenticated = environment.route === 'default' ? this.authService.isAuthenticated$ : Promise.resolve(true);
  isCollapsed = true;
  title: string | null = null;
  toasts: Toast[] = [];
  routes: Routes = [];
  routeConfig: Route | null = null;
  isNavigating = false;

  constructor(
    private authService: AuthService,
    private navigationService: NavigationService,
    private notificationService: NotificationService,
    private router: Router,
    private userService: UserService
  ) {
    this.navigationService.isCollapsed.subscribe((isCollapsed: boolean) => {
      this.isCollapsed = isCollapsed;
    });

    this.notificationService.toasts.subscribe((toasts) => {
      this.toasts = toasts;
    });

    this.router.events.pipe(filter((event) => event instanceof ActivationEnd)).subscribe((e: Event) => {
      setTimeout(() => {
        const route = e as ActivatedRoute;
        this.title = route?.snapshot?.data?.title;
        this.routeConfig = route.snapshot.routeConfig || null;
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        const routes = (this.routeConfig as any)?._loadedConfig?.routes as Route[];
        this.routes = routes?.filter((r) => !r?.data?.hidden) || [];

        this.isNavigating = false;
      }, 200);
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.isNavigating = true;
      this.title = null;
    });
  }

  onCloseEvent(): void {
    this.toasts.shift();
  }

  get toast(): Toast | null {
    return this.toasts.length ? this.toasts[0] : null;
  }

  getRoute(route: Route): Array<string | undefined> {
    return route.path ? [this.routeConfig?.path, route.path] : [this.routeConfig?.path];
  }

  collapse(): void {
    if (!this.isCollapsed) {
      this.navigationService.collapseNavbar();
    }
  }

  prepareRoute(outlet: RouterOutlet): string {
    return outlet?.activatedRouteData?.state;
  }

  auth(): void {
    this.authService.loginWithRedirect();
  }
}
