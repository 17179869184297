import { trigger, stagger, animate, style, transition, query, animateChild, group } from '@angular/animations';
const selector = 'tbd-form, .vertical-form, .die-action, .flex-h, .flex-v, .card-container, button, tbd-die';
const staggerLength = 10;
// export const pageTransition = trigger('pageTransition', [
//   transition('home <=> dice-roll', [
//     query(selector, style({ opacity: 0 })),
//     query(
//       selector,
//       stagger(staggerLength, [
//         style({ transform: 'translateX(-100vw)' }),
//         animate('0.6s cubic-bezier(0.16, 1, 0.3, 1)', style({ transform: 'translateX(0px)', opacity: 1 })),
//       ])
//     ),
//   ]),
//   transition(':leave', [])
// ]);
//   transition('* => *', [
//         query(':enter, :leave', style({ position: 'fixed', width: '100%' })),
//         query(':enter', style({ transform: 'translateX(100%)' })),
//         sequence([
//           query(':leave', animateChild()),
//           group([
//             query(':leave', [
//               style({ transform: 'translateX(0%)' }),
//               animate('500ms cubic-bezier(.75,-0.48,.26,1.52)', style({ transform: 'translateX(-100%)' }))
//             ]),
//             query(':enter', [
//               style({ transform: 'translateX(100%)' }),
//               animate('500ms cubic-bezier(.75,-0.48,.26,1.52)', style({ transform: 'translateX(0%)' }))
//             ])
//           ]),
//           query(':enter', animateChild())
//         ])
//       ])
// ]);

export const forTransition = trigger('forTransition', [
  transition(':enter', [
    query(selector, style({ opacity: 0 })),
    query(
      selector,
      stagger(staggerLength, [
        style({ transform: 'translateY(100vh)' }),
        animate('0.6s cubic-bezier(0.16, 1, 0.3, 1)', style({ transform: 'translateY(0px)', opacity: 1 })),
      ])
    ),
  ]),
  transition(':leave', [
    query(selector, style({ opacity: 1 })),
    query(selector, stagger(staggerLength, [animate('0.6s cubic-bezier(0.16, 1, 0.3, 1)', style({ opacity: 0 }))])),
  ]),
]);

// export const forTransition = trigger('forTransition', [
//   transition('* <=> *', [
//     query(':enter',
//       [style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))],
//       { optional: true }
//     ),
//     query(':leave',
//       animate('200ms', style({ opacity: 0 })),
//       { optional: true }
//     )
//   ])
// ]);

export const pageTransition = trigger('pageTransition', [
  transition('* <=> *', [
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }),
      ],
      { optional: true }
    ),
    query(':enter', [style({ left: '-100%' })], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':leave', [animate('150ms ease-out', style({ left: '100%' }))], { optional: true }),
      query(':enter', [animate('150ms ease-out', style({ left: '0%' }))], { optional: true }),
    ]),
    query(':enter', animateChild(), { optional: true }),
  ]),
]);
