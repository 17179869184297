import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Player, PlayerEntity, PlayerStats } from 'src/app/models/player';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DieStyle } from 'src/app/models/die';
import { UserService } from './user.service';
import { Session } from '../models/session';
import { NotificationService } from './notification.service';
@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private notificationService: NotificationService
  ) {
    this.userService.user.subscribe((dieStyle: DieStyle | null) => {
      if (dieStyle) {
        this.dieStyle.next(new DieStyle(dieStyle));
      }
    });
  }

  sessions: BehaviorSubject<Session[]> = new BehaviorSubject<Session[]>([]);
  currentSession: BehaviorSubject<Session | null> = new BehaviorSubject<Session | null>(null);
  players: BehaviorSubject<Player[]> = new BehaviorSubject<Player[]>([]);
  npcs: BehaviorSubject<Player[]> = new BehaviorSubject<Player[]>([]);
  currentPlayer: BehaviorSubject<Player | null> = new BehaviorSubject<Player | null>(null);
  dieStyle: BehaviorSubject<DieStyle | null> = new BehaviorSubject<DieStyle | null>(new DieStyle(null));
  apiURL = environment.apiURL;

  async getPlayer(id: string): Promise<void> {
    const player = await this.http.get<PlayerEntity>(`${this.apiURL}/players/${id}`).toPromise();
    this.currentPlayer.next(new Player(player));
  }

  async getPlayers(types: string[] = []): Promise<void> {
    const res = await this.http.get<PlayerEntity[]>(`${this.apiURL}/players?types=${types}`).toPromise();
    const players = res ? res.map((p: PlayerEntity) => new Player(p)) : [];
    this.players.next(players);
  }

  async getNpcs(): Promise<void> {
    const res = await this.http.get<PlayerEntity[]>(`${this.apiURL}/players/npcs/all`).toPromise();
    const players = res ? res.map((p: PlayerEntity) => new Player(p)) : [];
    this.npcs.next(players);
  }

  async createPlayer(player: any): Promise<PlayerEntity> {
    return await this.http.post<PlayerEntity>(`${this.apiURL}/players`, player).toPromise();
  }

  async updatePlayer(id: string, player: Player): Promise<PlayerEntity> {
    return await this.http.put<PlayerEntity>(`${this.apiURL}/players/${id}`, player).toPromise();
  }

  async updatePlayerStats(id: string, stats: PlayerStats): Promise<PlayerEntity> {
    return await this.http.patch<PlayerEntity>(`${this.apiURL}/players/${id}/stats`, stats).toPromise();
  }

  async updateIsNpc(id: string, isNpc: boolean): Promise<PlayerEntity> {
    return await this.http.patch<PlayerEntity>(`${this.apiURL}/players/${id}/is-npc/${isNpc}`, null).toPromise();
  }

  async addPlayer(): Promise<void> {
    await this.createPlayer({});
    await this.getPlayers();
  }

  async dealDamage(id: string, damage: number): Promise<void> {
    await this.http.patch<PlayerEntity>(`${this.apiURL}/players/${id}/damage/${damage}`, null).toPromise();
  }

  async saveDieStyle(id?: string, dieStyle?: DieStyle): Promise<void> {
    // const res = await this.http.put<DieStyle>(`${this.apiURL}/dice/${id}`, dieStyle).toPromise();
    if (dieStyle) {
      this.updateCss(dieStyle);
    }
    localStorage.setItem('dieStyle', JSON.stringify(dieStyle));
    this.dieStyle.next(new DieStyle(dieStyle));
  }

  updateCss(dieStyle: DieStyle): void {
    const primaryColorArray = dieStyle.primaryColor.split(',');
    primaryColorArray[2] = '25%)';

    const secondaryColorArray = dieStyle.secondaryColor.split(',');
    secondaryColorArray[2] = '50%)';

    document.documentElement.style.setProperty('--primary-color', primaryColorArray.toString());
    document.documentElement.style.setProperty('--secondary-color', secondaryColorArray.toString());
  }

  async getDieStyle(id?: string): Promise<void> {
    // const dieStyle = await this.http.get<DieStyle>(`${this.apiURL}/dice/${id}`).toPromise();
    let dieStyle;
    try {
      const dieJSON = localStorage.getItem('dieStyle');
      if (dieJSON) {
        dieStyle = JSON.parse(dieJSON);
      }
      if (dieStyle) {
        this.updateCss(dieStyle);
      }
      this.dieStyle.next(new DieStyle(dieStyle));
      Promise.resolve();
    } catch (e) {}
  }

  async damagePlayer(id: string, damage: number): Promise<void> {
    await this.http
      .put<PlayerEntity>(`${this.apiURL}/players/${id}/damage`, { damage: damage })
      .toPromise();
  }

  async delete(id: string): Promise<void> {
    await this.http.delete<void>(`${this.apiURL}/players/${id}`).toPromise();
  }

  async createSession(): Promise<void> {
    const session = {
      userId: this.dieStyle.getValue()?.userId,
    };
    await this.http.post<void>(`${this.apiURL}/sessions`, session).toPromise();
  }

  async getSession(id: string): Promise<void> {
    const res = await this.http.get<void>(`${this.apiURL}/sessions/${id}`).toPromise();
    this.currentSession.next(new Session(res));
  }

  async getSessions(): Promise<void> {
    const id = this.dieStyle.getValue()?.userId;
    const res = await this.http.get<Session[]>(`${this.apiURL}/sessions/me/${id}`).toPromise();
    const sessions = res ? res.map((s) => new Session(s)) : [];
    this.sessions.next(sessions);
  }

  async updateSession(id: string, session: any): Promise<void> {
    const sessionData = session;
    delete sessionData.players;
    await this.http.put<void>(`${this.apiURL}/sessions/${id}`, sessionData).toPromise();
    const toast = {
      title: 'Session saved',
      description: '',
      timeout: 1000,
    };
    this.notificationService.pushNotification(toast);
  }
}
