import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { PlayerService } from 'src/app/services/player.service';

@Injectable({ providedIn: 'root' })
export class DieStyleResolver implements Resolve<void> {
  constructor(private playerService: PlayerService) {}

  resolve(): Promise<void> {
    return this.playerService.getDieStyle();
  }
}
