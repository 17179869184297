import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageComponent } from '../components/core/page/page.component';
import { DieComponent } from '../components/die/die.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormComponent } from '../components/forms/form/form.component';
import { PlusMinusPipe } from '../pipes/plus-minus.pipe';
import { FilePickerComponent } from '../components/file-picker/file-picker.component';
import { InputDescriptionComponent } from '../components/forms/input-description/input-description.component';
import { DiceTrayComponent } from '../components/dice-tray/dice-tray.component';
import { PlayerCardPreviewComponent } from '../components/player-card-preview/player-card-preview.component';
import { CardComponent } from '../components/card/card.component';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from '../components/core/navbar/navbar.component';

@NgModule({
  imports: [CommonModule, FormsModule, ColorPickerModule, ReactiveFormsModule, RouterModule.forChild([])],
  declarations: [
    CardComponent,
    DiceTrayComponent,
    DieComponent,
    FilePickerComponent,
    FormComponent,
    InputDescriptionComponent,
    PageComponent,
    PlayerCardPreviewComponent,
    PlusMinusPipe,
    NavbarComponent,
  ],
  exports: [
    CardComponent,
    DiceTrayComponent,
    DieComponent,
    FilePickerComponent,
    FormComponent,
    InputDescriptionComponent,
    PageComponent,
    PlayerCardPreviewComponent,
    PlusMinusPipe,
    CommonModule,
    FormsModule,
    ColorPickerModule,
    ReactiveFormsModule,
    NavbarComponent,
  ],
})
export class SharedModule {}
