import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, Injectable, NgModule, isDevMode } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScullyLibModule } from '@scullyio/ng-lib';
import * as Sentry from '@sentry/browser';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { AccountProfileComponent } from './components/account-profile/account-profile.component';
import { MainComponent } from './components/core/main/main.component';
import { NavbarComponent } from './components/core/navbar/navbar.component';
import { ToastComponent } from './components/toast/toast.component';
import { RoutingModule } from './routing/routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './modules/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { AuthModule } from '@auth0/auth0-angular';
import { FiascoComponent } from './components/fiasco/fiasco.component';

Sentry.init({
  dsn: environment.sentry.dsn,
  release: `${environment.sentry.release}`,
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  handleError(error: any): void {
    if (isDevMode()) {
      console.error(error);
    }
    Sentry.captureException(error.originalError || error);
  }
}

@NgModule({
  declarations: [AccountProfileComponent, AppComponent, MainComponent, ToastComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DragDropModule,
    HttpClientModule,
    ScullyLibModule,
    RoutingModule,
    RouterModule,
    SharedModule,
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
    }),
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
