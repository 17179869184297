import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({ providedIn: 'root' })
export class UserResolver implements CanLoad {
  constructor(private userService: UserService, private authService: AuthService) {}

  canLoad(): Observable<boolean> {
    return new Observable((observer) => {
      // if (environment.overwriteDefault) {
      //   observer.next(false);
      //   observer.complete();
      // }
      this.authService.user$.subscribe((user) => {
        this.userService.getUserWithDice(user as any).then(() => {
          observer.next(true);
          observer.complete();
        });
      });
    });
  }
}
