import { Component, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { Toast } from '../../models/toast';

@Component({
  selector: 'tbd-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnDestroy {
  interval!: Subscription;
  progress = 0;
  @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Input() toast!: Toast;
  constructor() {
    this.interval = timer(0, 10).subscribe(() => {
      this.progress += 10;

      if (this.toast.timeout && this.progress >= this.toast.timeout) {
        this.close();
      }
    });
  }

  get progressPercentage(): number {
    return (this.progress / this.toast.timeout) * 100;
  }

  ngOnDestroy(): void {
    if (this.interval && this.interval.closed) {
      this.interval.unsubscribe();
    }
  }
  close(): void {
    if (this.interval && this.interval.closed) {
      this.interval.unsubscribe();
    }
    this.closeEvent.emit();
  }
}
