import { Component } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { NavItem } from 'src/app/models/nav-item';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import { Input } from '@angular/core';
import { Die } from 'src/app/models/die';

@Component({
  selector: 'tbd-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  isCollapsed = true;
  isMusicDialogOpen = false;
  isNavigating = false;
  navItems: NavItem[] = [];
  isAuthenticated = this.authService.isAuthenticated$;
  @Input() isDiceBar = false;
  constructor(private authService: AuthService, private navigationService: NavigationService, private router: Router) {
    this.navigationService.isCollapsed.subscribe((isCollapsed: boolean) => {
      this.isCollapsed = isCollapsed;
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.isNavigating = true;
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.isNavigating = false;
    });

    this.navItems = this.navigationService.getNavItems();
  }

  toggleCollapse(): void {
    this.navigationService.toggleNavbar();
  }

  signout(): void {
    this.authService.logout({ returnTo: window.location.origin });
  }

  openMusicDialog(): void {
    this.isMusicDialogOpen = !this.isMusicDialogOpen;
  }

  get imageSource(): string {
    return this.isCollapsed ? 'assets/logo.min.png' : 'assets/logo3.png';
  }
}
