import { Injectable } from '@angular/core';
import { NavItem } from '../models/nav-item';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  isCollapsed = new BehaviorSubject(true);
  getNavItems(): NavItem[] {
    return [
      {
        name: 'dice',
        link: 'dice/roll',
        title: 'dice',
        icon: 'dice',
      },
      {
        name: 'players',
        link: 'players/view',
        title: 'players',
        icon: 'users',
      },
      {
        name: 'session',
        link: 'sessions',
        title: 'sessions',
        icon: 'images',
      },
    ];
  }

  toggleNavbar(): void {
    this.isCollapsed.next(!this.isCollapsed.value);
  }

  collapseNavbar(): void {
    this.isCollapsed.next(true);
  }
}
