import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tbd-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() buttons: any[] = [];
  @Input() title = '';
  @Input() flexible = true;
  @Input() toggle = true;
  @Input() style = '';
  @Input() flex = false;
  @Input() tall = false;
  @Input() isVisible = true;
  classes = {};
  toggleCard(): void {
    this.isVisible = !this.isVisible;
  }

  ngOnInit(): void {
    this.classes = {
      auto: this.flexible,
      [this.style]: true,
      flex: this.flex,
      tall: this.tall,
    };
  }
}
