<nav id="navbar" [ngClass]="{ collapsed: isCollapsed, disabled: isNavigating, 'dice-bar': isDiceBar }" class="disabled">
  <div class="navbar" *ngIf="isDiceBar">
    <ng-content></ng-content>
  </div>
  <div class="navbar" *ngIf="!isDiceBar">
    <!-- <div class="logo-container">
      <img class="logo" [src]="imageSource" alt="Dungeon Stack logo" />
    </div> -->
    <button
      class="primary nav-link"
      (click)="toggleCollapse()"
      [attr.aria-label]="'NAVBAR.TOGGLE'"
      type="button"
      aria-label="toggle"
    >
      <i class="fas fa-bars"></i>
    </button>
    <!-- <tbd-account-profile [ngClass]="{ collapsed: isCollapsed }"></tbd-account-profile> -->
    <ng-container *ngIf="isAuthenticated | async">
      <a
        class="primary nav-link"
        *ngFor="let navItem of navItems"
        [routerLink]="navItem.link"
        routerLinkActive="active"
        [attr.aria-label]="navItem.title"
      >
        <span *ngIf="!isCollapsed">{{ navItem.title }}</span>
        <i class="fas fa-{{ navItem.icon }}"></i>
      </a>
    </ng-container>
    <button
      type="button"
      (click)="signout()"
      class="primary"
      *ngIf="isAuthenticated | async"
      type="button"
      [attr.aria-label]="'NAVBAR.SIGN-OUT'"
    >
      <span *ngIf="!isCollapsed">{{ 'sign out' }}</span>
      <i class="fas fa-sign-out-alt"></i>
    </button>
  </div>
</nav>
