import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { TbdUser } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  apiURL = environment.apiURL;
  user = new BehaviorSubject(null);

  async getUserWithDice(user: TbdUser): Promise<any> {
    return this.http
      .post<void>(`${this.apiURL}/auth`, user)
      .toPromise()
      .then((user: any) => {
        this.user.next(user);
        return user;
      });
  }
}

export interface SignUpParams {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface SignInParams {
  email: string;
  password: string;
}

export interface SessionEntity {
  email: string;
  password: string;
  userProfile: UserProfile;
}

export interface UserProfile {
  id: string;
}

export interface AuthUser {
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
}
