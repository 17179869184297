<div class="card-container" [ngClass]="classes">
  <div class="card-header with-action tbd-row bebas">
    {{ title }}
    <div>
      <ng-container *ngFor="let button of buttons">
        <button class="secondary disappear" type="button" (click)="button.click()">
          <i class="fa" [ngClass]="button.icon"></i>
        </button>
      </ng-container>
      <button class="secondary disappear" type="button" (click)="toggleCard()" *ngIf="flexible && toggle">
        <i class="fa" [ngClass]="{ 'fa-chevron-up': !isVisible, 'fa-chevron-down': isVisible }"></i>
      </button>
    </div>
  </div>
  <div class="card-content">
    <div class="card-body" *ngIf="isVisible">
      <ng-content></ng-content>
    </div>
  </div>
</div>
